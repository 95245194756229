import React, {Component} from 'react';
import {connect} from 'react-redux';
import l18n from 'src/assets/json/l18n.json';
import Textarea from 'react-textarea-autosize';
import {getAutoNote} from 'src/actions/commentsActions';

class CommentArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoGenerateIsActive: true,
		};
		this.autoGenerateCommentAreaText = this.autoGenerateCommentAreaText.bind(this);
		this.undoAutoGenerateCommentAreaText = this.undoAutoGenerateCommentAreaText.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	/**
	 * Private function used to add prefix for either nose or palates.
	 *
	 * @param {object} wineInformation
	 * @returns {array} notes[]
	 */
	_addPrefix = (wineInformation) => {
		const notes = [];
		const addPrefixIn = ['nose', 'palate'];

		addPrefixIn.forEach((prefixWord) =>
			Object.values(wineInformation[prefixWord])
				.filter((item) => typeof item !== 'string')
				.forEach((item) => item.forEach((i) => notes.push(`${prefixWord}${i}`)))
		);

		return notes;
	};

	/**
	 * Get list of all the notes for the user selection.
	 *
	 * @param {object} wineInformation
	 * @returns {array} notes
	 */
	getAutoGeneratedNotes = (wineInformation) => {
		const allNotes = [];
		const keys = ['appearance', 'palate', 'nose', 'observations'];

		const tasteData = keys.reduce((result, key) => ({...result, [key]: wineInformation[key]}), {});

		// returns list of string
		Object.values(tasteData).forEach((item) => allNotes.push(...Object.values(item)));

		const prefixAddedNotes = this._addPrefix(wineInformation);

		const noteList = allNotes.filter((note) => typeof note === 'string');
		const notes = noteList.concat(...prefixAddedNotes);

		return notes;
	};

	autoGenerateCommentAreaText() {
		const {autoGenerateIsActive} = this.state;
		const {selectedItems, commentCallBack, commentKey, getAutoNote, online} = this.props;
		if (autoGenerateIsActive && online) {
			const notes = this.getAutoGeneratedNotes(selectedItems);

			getAutoNote(notes, commentCallBack, commentKey);
			this.setState({
				autoGenerateIsActive: false,
			});
		}
	}

	undoAutoGenerateCommentAreaText() {
		this.setState({
			autoGenerateIsActive: true,
		});
		this.props.commentCallBack({[this.props.commentKey]: ''});
	}

	handleChange(event) {
		const target = event.target;
		const value = target.value;
		this.props.commentCallBack({[this.props.commentKey]: value});
	}

	render() {
		const {
			connectionStatus: {online},
		} = this.props;

		const title = this.props.commentKey ? (
			<h2 className="ca-header">{l18n[this.props.commentKey]}</h2>
		) : null;

		const miniLabel = this.props.commentKey ? (
			<span className="ca-mini-label">{l18n[this.props.commentKey]}</span>
		) : null;

		let controls = '';
		let autoGenerateBtnClasses = ['pulsing', 'auto-generate-btn'];
		let showUndo = false;

		if (!this.state.autoGenerateIsActive) {
			autoGenerateBtnClasses.push('disable');
			showUndo = true;
		}

		if (!online) {
			autoGenerateBtnClasses.push('disable');
		}

		if (this.props.showControls) {
			controls = (
				<div className="ca-controls">
					{showUndo ? (
						<button className="undo-btn" onClick={this.undoAutoGenerateCommentAreaText}>
							Undo
						</button>
					) : null}
					<button
						className={autoGenerateBtnClasses.join(' ')}
						onClick={this.autoGenerateCommentAreaText}
						data-toggle="tooltip"
						data-html="true"
						title={!online && 'Please get online to autogenerate tasting note.'}
					>
						Auto-generate Summary
					</button>
				</div>
			);
		}

		return (
			<div className="comment-area">
				{title}
				<div className="ca-input">
					<Textarea value={this.props.content} onChange={this.handleChange} />
					{miniLabel}
				</div>
				{controls}
			</div>
		);
	}
}

CommentArea.defaultProps = {
	showControls: true,
};

function mapStateToProps(state) {
	return {
		comments: state.comments,
		connectionStatus: state.offline,
	};
}

export default connect(
	mapStateToProps,
	{getAutoNote}
)(CommentArea);
