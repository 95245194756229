const clubsConstants = {
	FETCH_CLUBS: 'FETCH_CLUBS',
	FETCH_CLUBS_PENDING: 'FETCH_CLUBS_PENDING',
	FETCH_CLUBS_FULFILLED: 'FETCH_CLUBS_FULFILLED',
	FETCH_CLUBS_REJECTED: 'FETCH_CLUBS_REJECTED',
	FETCH_SELECTED_CLUB: 'FETCH_SELECTED_CLUB',
	FETCH_SELECTED_CLUB_PENDING: 'FETCH_SELECTED_CLUB_PENDING',
	FETCH_SELECTED_CLUB_FULFILLED: 'FETCH_SELECTED_CLUB_FULFILLED',
	FETCH_SELECTED_CLUB_REJECTED: 'FETCH_SELECTED_CLUB_REJECTED',
	SEARCH_CLUBS: 'SEARCH_CLUBS',
	SEARCH_CLUBS_PENDING: 'SEARCH_CLUBS_PENDING',
	SEARCH_CLUBS_FULFILLED: 'SEARCH_CLUBS_FULFILLED',
	SEARCH_CLUBS_REJECTED: 'SEARCH_CLUBS_REJECTED',

	CREATE_PENDING: 'CREATE_CLUB_PENDING',
	CREATE_SUCCESS: 'CREATE_CLUB_FULFILLED',
	CREATE_ERROR: 'CREATE_CLUB_ERROR',

	UPDATE_PENDING: 'UPDATE_CLUB_PENDING',
	UPDATE_SUCCESS: 'UPDATE_CLUB_FULFILLED',
	UPDATE_ERROR: 'UPDATE_CLUB_ERROR',

	SB_MY_CLUBS: 'SB_MY_CLUBS',

	CLEAR_SELECTED_CLUB: 'CLEAR_SELECTED_CLUB',
};

export default clubsConstants;
