const appConstants = {
	SET_ACTIVE_FORM: 'SET_ACTIVE_FORM',

	OPEN_SIDENAV: 'OPEN_SIDENAV',
	CLOSE_SIDENAV: 'CLOSE_SIDENAV',
	SET_NAV_OFFCANVAS: 'SET_NAV_OFFCANVAS',

	SET_ADVANCED_OPTIONS: 'SET_ADVANCED_OPTIONS',

	RRS_DISMISS_SNACK: 'RRS_DISMISS_SNACK',
	OPEN_APP_ERROR_MODAL: 'OPEN_APP_ERROR_MODAL',
	TOGGLE_APP_ERROR_MODAL: 'TOGGLE_APP_ERROR_MODAL',
	DISMISS_APP_ERROR_MODAL: 'DISMISS_APP_ERROR_MODAL',

	APP_ERROR: 'APP_ERROR',
};

export default appConstants;
