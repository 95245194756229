const userConstants = {
	INITIATE_LOGIN: 'INITIATE_LOGIN',
	INITIATE_LOGOUT: 'INITIATE_LOGOUT',

	LOGIN_FULFILLED: 'LOGIN_FULFILLED',
	LOGIN_FAILED: 'LOGIN_FAILED',

	CREATE_SUCCESS: 'CREATE_USER_FULFILLED',
	CREATE_ERROR: 'CREATE_USER_ERROR',

	RESET_PASS_SUCCESS: 'RESET_PASS_FULFILLED',
	RESET_PASS_ERROR: 'RESET_PASS_ERROR',

	CHECK_USER: 'CHECKING_FOR_LOGGEDIN_USER',
};

export default userConstants;
